import { PatientDetails, PatientEditableData } from '../PatientView/types'

export const DEFAULT_PATIENT_DETAILES: PatientDetails = {
    patientUuid: '',
    mrn: '',
    firstName: '',
    lastName: '',
    visitDiagnosis: '',
    visitLengthOfStaySinceAdmissionHours: 0,
    visitFollowingDoctorName: '',
    visitIsolationStatus: null,
    locationServiceName: null,
    locationUnitName: '',
    locationRoomName: '',
    locationBedName: '',
    patientGender: '',
    patientAge: 0,
    communityCareInstitution: null,
    communityIntervenantPivotName: null,
    communityProgram: null,
    communityLastInterventionDatetime: null,
    communityInterventionProgram: null,
    patientVisits: [],
    patientSurgeries: [],
    patientResults: [],
    lastModifiedDate: '',
    familyMdName: null,
    patientCareFactors: null,
    visitExpectedPlannedDischargeHours: null,
    actualTransferStatus: null,
    actualTransferStatusStartDateTime: null,
    patientProvenance: null,
    patientTerritory: null,
}

export const DEFAULT_PATIENT_DATA: PatientEditableData = {
    patientId: null,
    proceduresDone: null,
    surgicalComplications: null,
    correctionToMd: null,
    dischargePlan: null,
    ventilationSetting: null,
    ivAccess: null,
    objectivesAndConcerns: null,
    rhythm: null,
    drainsDSGSInteg: null,
    pastMedicalHistory: null,
    autonomyAndMobility: null,
    nutritionAndHydration: null,
    eliminationAndSkin: null,
    sleep: null,
    mobilityPlan: null,
    helpProgramInvolved: false,
    professionalsInvolved: null,
    caregiverCapacity: null,
    homeScreening: null,
    dsieStatus: null,
    prescriptionMedication: null,
    allergiesAndIntolerances: null,
    other: null,
    birthWeight: null,
    gestationalAgeAtBirth: null,
    correctedGestationalAgeAtPresent: null,
    nextSteps: null,
    recommendedFuDelay: null,
    nextHusScreen: null,
    nextRopScreen: null,
    dateOfHearingScreening: null,
    recommendedHusFuDelay: null,
    dateOfInitialHusScreening: null,
    dateOfLastHusScreening: null,
    dateOfInitialScreening: null,
    dateOfLastScreening: null,
    checkedBy: null,
    checkedByDate: null,
}
