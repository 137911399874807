import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    ShowTermsAndConditionsResponse,
    GuideAndConditionsResponse,
} from './type'
import { prepareHeaders } from '../helpers/prepareHeaders'

const usersBaseURL = `${process.env.REACT_APP_SCRUBCHART_API_URL}`

export const termsApi = createApi({
    reducerPath: 'termsApi', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: usersBaseURL,
        prepareHeaders,
    }),

    tagTypes: ['Show', 'TermsOfService', 'Inflow', 'Lengthofstay', 'UserGuide'],

    endpoints: (builder) => ({
        getShowTermsOfService: builder.query<
            ShowTermsAndConditionsResponse,
            void
        >({
            query: () => `api/TermsAndConditions/show`,
            providesTags: [{ type: 'Show', id: 'SHOW' }],
            // transformResponse: () => {
            //     return termsAndConditionsMock
            // },
        }),
        getTermsOfServiceForPopup: builder.query<
            GuideAndConditionsResponse,
            void
        >({
            query: () => `/api/TermsAndConditions`,
            providesTags: [{ type: 'TermsOfService', id: 'LIST_TERMS' }],
        }),
        acceptTermsOfService: builder.mutation<any, void>({
            query: () => ({
                url: `/api/TermsAndConditions/accept`,
                method: 'POST',
            }),
        }),
        getUserGuide: builder.query<GuideAndConditionsResponse, void>({
            query: () => `/api/userguide`,
            providesTags: [{ type: 'UserGuide', id: 'USERGUID' }],
        }),
    }),
})

export const selectGetTermsResult = termsApi.endpoints.acceptTermsOfService

export const {
    useGetShowTermsOfServiceQuery,
    useGetTermsOfServiceForPopupQuery,
    useAcceptTermsOfServiceMutation,
    useGetUserGuideQuery,
    useLazyGetTermsOfServiceForPopupQuery,
    useLazyGetShowTermsOfServiceQuery,
} = termsApi
