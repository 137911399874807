import {
    PatientDetailsResponse,
    PatientEditableData,
} from 'redux/rtk/PatientView/types'

export const MOCK_PATIENT_VIEW: PatientDetailsResponse = {
    patientDetails: {
        patientUuid: '06a78bac-1dcf-4c02-9a37-4168b29db9fb',
        mrn: '1420792',
        firstName: 'EMMA',
        lastName: 'ROY',
        visitDiagnosis: 'gait and mobility abnormality',
        visitLengthOfStaySinceAdmissionHours: 2150,
        visitFollowingDoctorName: 'GILLMAN MARCIA',
        visitIsolationStatus: null,
        locationServiceName: null,
        locationUnitName: 'K2CU',
        locationRoomName: 'K0216',
        locationBedName: 'A',
        patientGender: 'FEMALE',
        patientAge: 76,
        communityCareInstitution: 'CSSS De Therese de Blainville',
        communityIntervenantPivotName: 'Virginie Combet',
        communityProgram: 'SAPA, Serv. psychosociaux SAD',
        communityLastInterventionDatetime: null,
        communityInterventionProgram: null,
        patientVisits: [
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 36,
                serviceName: 'GER',
                visitStartDatetime: '2025-01-14T17:44:00',
                visitEndDatetime: null,
            },
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 112,
                serviceName: 'ED',
                visitStartDatetime: '2025-01-10T01:30:41.52',
                visitEndDatetime: '2025-01-14T17:30:28',
            },
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 3,
                serviceName: 'ED',
                visitStartDatetime: '2025-01-07T14:31:53.677',
                visitEndDatetime: '2025-01-07T17:27:54',
            },
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 73,
                serviceName: 'CARD',
                visitStartDatetime: '2024-12-01T18:01:00',
                visitEndDatetime: '2024-12-04T18:52:00',
            },
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 281,
                serviceName: 'CARD',
                visitStartDatetime: '2024-11-09T18:22:00',
                visitEndDatetime: '2024-11-21T10:55:00',
            },
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 20,
                serviceName: 'ED',
                visitStartDatetime: '2024-11-08T21:35:18.473',
                visitEndDatetime: '2024-11-09T18:02:01',
            },
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                lengthOfStayServiceHours: 661,
                serviceName: 'FAM',
                visitStartDatetime: '2024-09-23T07:50:00',
                visitEndDatetime: '2024-10-20T20:45:00',
            },
        ],
        patientSurgeries: [
            {
                patientUuid: '6444fa25-e889-405d-902e-4629df3b5849',
                surgeryName:
                    'TAVI (transcatheter aortic valve implantation) femoral approach',
                surgeryDate: '2024-12-02T00:00:00',
            },
            {
                patientUuid: '8223fa12-f899-412d-91ce-7623df2b9143',
                surgeryName: 'CABG (Coronary Artery Bypass Grafting)',
                surgeryDate: '2025-01-28T00:00:00',
            },
        ],
        lastModifiedDate: '2024-12-04T06:57:14.931116',
        familyMdName: 'MARTELLO CRISTIANO 14591, J-JO',
        patientCareFactors: [
            'has_clsc_mental_health_record',
            'is_highly_vulnerable',
            'has_mental_health_diagnosis',
            'is_75_or_more',
        ],
        visitExpectedPlannedDischargeHours: 303,
        actualTransferStatus: 'medically active',
        actualTransferStatusStartDateTime: null,
        patientProvenance: 'Clinic',
        patientTerritory: "Est de l'Île de Montréal",
    },
}

export const PATIENT_DETAILES_MOCK = {
    patientDetails: {
        patientId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        proceduresDone: 'string',
        surgicalComplications: 'string',
        correctionToMd: 'string',
        dischargePlan: 'string',
        objectivesAndConcerns: 'string',
        otherVentilationAndIvAccess: 'string',
        rhythm: 'string',
        drainsDSGSInteg: 'string',
        pastMedicalHistory: 'string',
        autonomyAndMobility: 'string',
        nutritionAndHydration: 'string',
        eliminationAndSkin: 'string',
        sleep: 'string',
        mobilityPlan: 'string',
        helpProgramInvolved: true,
        professionalsInvolved: 'string',
        caregiverCapacity: 'string',
        homeScreening: 'string',
        dsieStatus: 'string',
        prescriptionMedication: 'string',
        allergiesAndIntolerances: 'string',
        other: 'string',
        birthWeight: 0,
        gestationalAgeAtBirth: null,
        correctedGestationalAgeAtPresent: 0,
        nextSteps: 'string',
        recommendedFuDelay: 'string',
    },
}

export const MOCK_PATIENT_DETAILES: PatientEditableData = {
    patientId: '1df41d1a-e94a-40b6-bdd0-fdbe0f5755e7',
    proceduresDone: 'Appendectomy performed successfully without complications',
    surgicalComplications: 'No post-operative complications reported',
    correctionToMd: 'Updated medication dosage to reflect current needs',
    dischargePlan:
        'Patient to be discharged on 2025-02-10 with follow-up scheduled',
    objectivesAndConcerns:
        'Ensure wound healing and monitor for signs of infection',
    rhythm: 'Sinus rhythm observed with no abnormalities',
    ventilationSetting: 'Oxygen therapy set to 2L/min via nasal cannula',
    ivAccess: 'Peripheral IV line placed in the right forearm',
    drainsDSGSInteg: 'Surgical drain functioning properly; no leakage noted',
    pastMedicalHistory: 'History of hypertension and type 2 diabetes',
    autonomyAndMobility: 'Patient is able to walk with minimal assistance',
    nutritionAndHydration:
        'Oral intake adequate; maintaining hydration through IV fluids',
    eliminationAndSkin: 'No issues with bowel or bladder function; skin intact',
    sleep: 'Reports difficulty sleeping due to discomfort.',
    mobilityPlan: 'Encourage ambulation three times daily to improve mobility',
    helpProgramInvolved: true,
    professionalsInvolved:
        'Surgeon, physiotherapist, and dietitian actively involved in care',
    caregiverCapacity:
        'Caregiver is trained and capable of managing basic care at home',
    homeScreening: 'Home environment deemed safe for recovery',
    dsieStatus: 'No signs of systemic inflammatory response',
    prescriptionMedication:
        'Prescribed ibuprofen for pain and amoxicillin as a prophylactic',
    allergiesAndIntolerances:
        'Allergic to penicillin; no food intolerances reported',
    other: 'Patient expresses concerns about returning to work post-recovery',
    birthWeight: null,
    gestationalAgeAtBirth: null,
    correctedGestationalAgeAtPresent: null,
    nextSteps:
        'Follow up with primary care physician and complete physiotherapy',
    dateOfHearingScreening: null,
    recommendedFuDelay: 'Follow up recommended after 2 weeks',
    recommendedHusFuDelay: 'Repeat head ultrasound in 4 weeks',
    dateOfInitialHusScreening: null,
    dateOfLastHusScreening: null,
    nextRopScreen: null,
    nextHusScreen: '2024-12-15T14:35:49.946Z',
    dateOfInitialScreening: '2024-12-15T14:35:49.946Z',
    dateOfLastScreening: '2024-12-15T14:35:49.946Z',
    checkedBy: 'Dr. Emily Carter',
    checkedByDate: '2024-12-15T14:35:49.946Z',
}
