export type NavigationItem = {
    title: string
    link: string
    width?: string | number
}

const MenuOptions: NavigationItem[] = [
    {
        title: 'JGH Management View',
        link: '/management_status',
        width: 210,
    },
    {
        title: 'Emergency department',
        link: '/ed_live',
        width: 210,
    },
    {
        title: 'Critical Care View',
        link: '/critical-care',
        width: 180,
    },
    {
        title: 'Operating Room View',
        link: '/operating-room',
        width: 180,
    },
    // {
    //     title: 'Dashboard',
    //     link: '/dashboard',
    // },
    // {
    //     title: 'Huddle',
    //     link: '/huddle',
    // },
    // {
    //     title: 'Meeting',
    //     link: '/huddle-meeting',
    // },
    // {
    //     title: 'Resources',
    //     link: '/imaging-resources',
    // },
    // {
    //     title: 'ER Status',
    //     link: '/er-status',
    // },
]

export default MenuOptions
